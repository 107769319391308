// import { getHostName } from '../utils/urlUtils';
import { _playAudio } from './media';
import { v4 as uuidv4 } from 'uuid';
// Mobile.initPageEnd();
var _websocket = null;
var _options = {
  // llm_model: '',
  platform: 'mobile',
  use_search: false,
  use_quivr: false,
  use_multion: false,
  // language: 'chinese',
  language: 'en_us',
};
var _message = null;
var _audioBlobList = [];
var _isPlaying = false;

export function _connect(urlOptions, option) {
  if (_websocket !== null && _websocket.readyState === WebSocket.CONNECTING) {
    return;
  }
  // let uri = 'ws://';
  // let uri = 'wss://';
  // if (window.location.protocol === 'https:') {
  //   uri = 'wss://';
  // }
  // const host = getHostName();
  let uri = '';
  // const host = process.env.REACT_APP_WEB_SOCKET;
  const sessionId = uuidv4().replace(/-/g, '');
  // uri = host + '/ws/' + sessionId + _getWebsocketOptions(urlOptions);
  uri +=
    'wss://api.bubble.shenmo-ai.net' +
    '/ws/' +
    sessionId +
    _getWebsocketOptions(urlOptions);
  // uri = `wss://api.bubble.shenmo-ai.net/ws/` + sessionId + _getWebsocketOptions(urlOptions);
  try {
    console.log(`连接websocket:[uri=${uri}][session_id=${sessionId}]`);
    _websocket = new WebSocket(uri);
    _websocket.onopen =
      option.onConnected instanceof Function ? option.onConnected : () => {};
    _websocket.onmessage =
      option.onAnswer instanceof Function ? option.onAnswer : () => {};
    _websocket.onerror = error => {
      // 判断websocket是否已断连
      if (_websocket.readyState === WebSocket.OPEN) {
        _disconnect(1006, '发生异常');
      }
    };
    _websocket.onclose = closeEvent => {
      const code = closeEvent.code;
      let reason = closeEvent.reason;
      _websocket = null;
      if (option.onClosed instanceof Function) {
        if (reason === '') {
          switch (code) {
            case 1003:
              reason = '数据格式错误';
              break;
            default:
              reason = '连接失败';
              break;
          }
        }
        option.onClosed(code, reason);
      }
    };
  } catch (error) {
    throw error;
  }
}
export function _disconnect(code, reason) {
  console.log(_websocket, '_websocket');
  if (_websocket !== null) {
    _websocket.close(code, reason);
  }
}
export function _send(text) {
  if (_websocket !== null && _websocket.readyState === WebSocket.OPEN) {
    console.log('发送文本:' + text);
    _websocket.send(text);
  }
}
export async function _handleAnswer(event, option) {
  let isInsert = false;
  if (_message === null) {
    _message = _createMessage();
    isInsert = true;
  }
  const data = event.data;
  if (typeof data === 'string') {
    if (/^\[end/.test(data)) {
      _message = null;
      // // 无语音
      // if (_audioBlobList.length === 0) {
      //   if (option.onEnd instanceof Function) {
      //     option.onEnd();
      //   }
      //   return;
      // }
      // _runPlay(() => {
      //   if (option.onEnd instanceof Function) {
      //     option.onEnd();
      //   }
      // });
      return;
    }
    // // 空字符串
    // if (data === '') {
    //   return;
    // }
    // _message.text += (_message.text === '' ? '' : '\n') + data;
    _message.text += data;
    if (option.onData instanceof Function) {
      option.onData(_message, isInsert);
    }
  }
  if (data instanceof Blob) {
    _play(data, () => {
      if (option.onEnd instanceof Function) {
        option.onEnd();
      }
    });
    // _audioBlobList.push(data);
  }
}
export function _createMessage(isUser = false) {
  return {
    id: uuidv4().replace(/-/g, '') + new Date().getTime(),
    speaker: isUser ? 'user' : 'ai',
    text: '',
  };
}
function _getWebsocketOptions(urlOptions) {
  console.log(urlOptions, 'urlOptionsurlOptions');
  if (urlOptions.scene_id) {
    _options.platform = 'mp';
  }
  const options = {
    ..._options,
    ...urlOptions,
  };
  // const keys = Object.keys(_options);
  const keys = Object.keys(options);
  if (keys.length === 0) {
    return '';
  }
  let string = '';
  keys.forEach(key => {
    // string += '&' + key + '=' + _options[key];
    string += '&' + key + '=' + options[key];
  });
  console.log('websocket参数:' + string);
  return string.replace(/^&/, '?');
  // return string;
}
function _play(blob, onPlayEnd) {
  _audioBlobList.push(blob);
  if (_isPlaying) {
    return;
  }
  _runPlay(onPlayEnd);
}
async function _runPlay(onPlayEnd) {
  if (_audioBlobList.length === 0) {
    // localStorage.setItem('sayIng', false);
    localStorage.setItem('sayIng', false);
    onPlayEnd();
    return;
  }
  console.log('开始说话');
  localStorage.setItem('sayIng', true);
  // localStorage.setItem('sayIng', true);
  _isPlaying = true;
  // 开始sayIng
  const blob = _audioBlobList.splice(0, 1)[0];
  const arrayBuffer = await blob.arrayBuffer();
  _playAudio(arrayBuffer, () => {
    _isPlaying = false;
    _runPlay(onPlayEnd);
  });
}
