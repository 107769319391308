import { useRef } from 'react';

const LongPressButton = ({
  disabled,
  showRecord,
  sayIng,
  onLongPressStart,
  onLongPressMove,
  onLongPressEnd,
}) => {
  const timerForLongPress = useRef(null);
  const isLongPress = useRef(false);
  const initialTouch = useRef(null);

  const _clearTimer = () => {
    if (timerForLongPress.current !== null) {
      clearTimeout(timerForLongPress.current);
      timerForLongPress.current = null;
    }
  };

  const onTouchStart = event => {
    if (disabled) {
      return;
    }
    isLongPress.current = false;
    // if (event.changedTouches.length > 0) {
    //   initialTouch.current = event.changedTouches[0];
    // }
    // timerForLongPress.current = setTimeout(() => {
    isLongPress.current = true;
    console.log('执行');
    onLongPressStart();
    // }, 350);
  };

  const onTouchMove = event => {
    if (disabled) {
      return;
    }
    if (isLongPress.current) {
      onLongPressMove(event);
      return;
    }
    // 未长按判断滑动
    if (event.changedTouches.length > 0) {
      const touch = event.changedTouches[0];
      const x = touch.clientX - initialTouch.current.clientX;
      const y = touch.clientY - initialTouch.current.clientY;
      if (Math.abs(x) > 20 || Math.abs(y) > 20) {
        _clearTimer();
      }
    }
  };

  const onTouchEnd = () => {
    if (disabled) {
      return;
    }
    _clearTimer();
    if (isLongPress.current) {
      onLongPressEnd();
    }
  };

  return (
    // <div
    //   className={disabled ? 'send-button send-button-disabled' : 'send-button'}
    //   // onTouchStart={onTouchStart}
    //   onClick={onTouchStart}
    //   onTouchMove={onTouchMove}
    //   onTouchEnd={onTouchEnd}
    // >
    //   按住说话
    // </div>
    <div className='talk-box'>
      <img
        onClick={onTouchStart}
        style={{ display: !showRecord && !sayIng ? 'block' : 'none' }}
        className='talk-ball'
        mode='widthFix'
        src='https://statics.shenmo-ai.com/sales/admin/172413983615680208456ball.png'
        alt=''
      />
      <img
        className={sayIng ? 'sayz-record' : 'none-btn'}
        mode='widthFix'
        src='https://statics.shenmo-ai.com/sales/admin/1724224617069147195514sayz.png'
        alt=''
      />
      <img
        className='cancal-record'
        style={{ display: showRecord ? 'flex' : 'none' }}
        mode='widthFix'
        src='https://statics.shenmo-ai.com/sales/admin/1724224516711928451528cancalRacord.png'
        alt=''
      />
      <img
        className='sayz-record'
        style={{ display: showRecord ? 'flex' : 'none' }}
        mode='widthFix'
        src='https://statics.shenmo-ai.com/sales/admin/1724224617069147195514sayz.png'
        alt=''
      />
      <img
        onClick={onTouchEnd}
        className='send-record'
        style={{ display: showRecord ? 'flex' : 'none' }}
        mode='widthFix'
        src='https://statics.shenmo-ai.com/sales/admin/1724224679583254728142sendRacord.png'
        alt=''
      />
      {/* <image @click="handleCancal" v-show="(!sendBut || openSay === 1) && !sayIng" class="cancal-record" mode="widthFix" src="https://statics.shenmo-ai.com/sales/admin/1724224516711928451528cancalRacord.png" alt="" />
    <image v-show="(!sendBut || openSay === 1) && !sayIng" class="sayz-record" mode="widthFix" src="https://statics.shenmo-ai.com/sales/admin/1724224617069147195514sayz.png" alt="" />
    <image v-show="sayIng" class="sayz-record" mode="widthFix" src="https://statics.shenmo-ai.com/sales/admin/1724224617069147195514sayz.png" alt="" />
    <image @click="handleTouchEnd" v-show="(!sendBut || openSay === 1) && !sayIng" class="send-record" mode="widthFix" src="https://statics.shenmo-ai.com/sales/admin/1724224679583254728142sendRacord.png" alt="" /> */}
    </div>
  );
};

export default LongPressButton;
